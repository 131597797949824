.sidebar {
    background-color: #21484b;
}

.sidebar .nav-link {
    color: white !important;
}


.sidebar .nav-parent>a {
    color: white !important;
}

.sidebar .profile-info .profile-title,
.sidebar .profile-info .profile-status,
.sidebar .profile-info h5 {
    color: white !important;
}

.sidebar .logo-mini h3,
.sidebar .logo-full h4 {
    color: white;
}

.logo-full {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.logo-full h3 {
    margin: 0;
    text-align: center;
    color: white;
}

.profile-name {
    color: white !important;
}

.nav-parent .nav li:hover a {
    background-color: green !important;
}

.active-nav-item,
.active-nav-item a {
    background-color: darkorange !important;
}