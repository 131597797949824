.content {
    padding: 20px;
  }
  
  .page-title .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .box {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .panel_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
  }
  
  .panel_header .btn {
    font-size: 16px;
  }
  
  .award-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .award-card {
    margin-bottom: 20px;
    width: 100%; /* Ensures full width in smaller screens */
  }
  
  .card {
    background: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
  }
  
  .card-body {
    padding: 15px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .media-container {
    margin-bottom: 15px;
  }
  
  .media-img, .media-video, .media-iframe {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  
  .card-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-actions i {
    font-size: 20px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .card-actions i:hover {
    color: #007bff;
  }
  
  /* Ensure 3 items per row on medium screens and larger */
  @media (min-width: 768px) {
    .award-card {
      width: 33.33%; /* 3 cards per row */
    }
  }
  