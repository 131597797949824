.testimonial-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
  }
  
  .testimonial-header h5 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .testimonial-header .rating i {
    margin-right: 2px;
  }
  
  .testimonial-actions i {
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .pointer:hover {
    transform: scale(1.1);
  }
  
  .page-title h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .panel_header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
  }
  
  .content-body {
    padding: 1.5rem 0;
  }
  